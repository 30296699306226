#language-selector {
  z-index:998;
  width:100%;
  position:fixed;
  top:0;
  bottom:0;

}

#language-selector ul {
  position:fixed;
  right:5px;
  top:5px;
  list-style:none;
  padding:5px 0;
  background-color:#292929;
  box-shadow:1px 1px 17px rgba(0,0,0,0.1);
  z-index:999;
}

.translator-option {
  padding:0 30px;
  font-family:'Space Mono', sans-serif;
  font-weight:700;
  size:15px;
  line-height:39px;
  width:100%;
}

.translator-option:hover {
  text-decoration:underline;
  cursor:pointer;
}
