header {
  background-color:#1C1C1C;
  color:white;
  height:20%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-bottom:5px;
}

.header-row {
  display:flex;
  justify-content:space-between;
  line-height:0;
  padding:5px 20px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  text-transform:uppercase;
  font-weight:500;
  font-size:40px;
}



@media (orientation: landscape) and (max-width: 736px) {
   .title {
      font-size:24px;
   }

   .icon {
    width:20px;
   }

   .header-row {
    padding:0 10px;
   }
}
