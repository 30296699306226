.Gallery{
  height: 60vh;
  overflow:scroll;
	color: white;
	text-align: center;
  background-color:#0e0e0e;
}

#gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  justify-items: center;
  grid-gap: 0;
}

.gallery-item {
  overflow:hidden;
  width: 100%;
}

.gallery-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transform: scale(1.17);
  transition: transform 0.5s, opacity 0.5s;
}

.gallery-item:hover img {
  opacity: 0.8;
  cursor:pointer;
  transform: scale(1.15);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Gallery::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Gallery {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 1500px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 1200px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 1050px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 850px) {
  .gallery-item img {
    height:300px;
  }

}

@media (max-width: 750px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .gallery-item img {
    height:250px;
  }
}

@media (max-width: 450px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .gallery-item img {
    height:200px;
  }
}

@media only screen and (min-width: 992px) {
  .Gallery {
    height:65vh;
  }
}


