body, html, .App {
  height:100% !important;
  min-height: -webkit-fill-available;
  background-color:#1C1C1C;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0 auto;
  padding:0;
}

h1, h2 {
  font-family: 'Proxima Nova', sans-serif;
}

h3, h4, h5 {
  font-family: 'Space Mono', sans-serif;
}

.icon {
  width:36px;
}

.hidden {
  display:none;
  visibility:hidden;
}


