#viz {
  height:60vh;
  overflow:hidden;
}



@media only screen and (min-width: 992px) {
  #viz {
    height:65vh;
  }
}
