.VizGallery{
  height: 60vh;
  overflow:scroll;
	color: white;
	text-align: center;
  background-image: linear-gradient(#0e0e0e, #4e4e4e);
}

#vizgallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
  justify-items: center;
  grid-gap: 5px;
}

.gallery-item {
  overflow:hidden;
  width: 100%;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.017);
  transition: transform 0.5s, opacity 0.5s;
}

.gallery-item:hover img {
  opacity: 0.8;
  cursor:pointer;
  transform: scale(1.00);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.VizGallery::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.VizGallery {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 1500px) {
  #vizgallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1200px) {
  #vizgallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1050px) {
  #vizgallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px) {
  #vizgallery {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  #vizgallery {
    grid-template-columns: 1fr;
  
  }
}

@media only screen and (min-width: 992px) {
  .VizGallery {
    height:65vh;
  }
}


