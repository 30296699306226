#switcher {
  width:266px;
  height:50px;
  border:1px solid #BBBBBB;
  display:flex;
  justify-content:center;
  cursor:pointer;
}

.switch-view {
  width:133px;
  display:flex;
  justify-content:center;
  border:1px solid #BBBBBB;
}

.switch-view.selected {
  background-color:white;
}

@media (orientation: landscape) and (max-width: 736px) {
   #switcher {
    width:240px;
    height:20px;
    margin-top:5px;
   }
}
