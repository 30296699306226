body, html, .App {
  height:100% !important;
  min-height: -webkit-fill-available;
  background-color:#1C1C1C;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0 auto;
  padding:0;
}

h1, h2 {
  font-family: 'Proxima Nova', sans-serif;
}

h3, h4, h5 {
  font-family: 'Space Mono', sans-serif;
}

.icon {
  width:36px;
}

.hidden {
  display:none;
  visibility:hidden;
}



header {
  background-color:#1C1C1C;
  color:white;
  height:20%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-bottom:5px;
}

.header-row {
  display:flex;
  justify-content:space-between;
  line-height:0;
  padding:5px 20px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  text-transform:uppercase;
  font-weight:500;
  font-size:40px;
}



@media (orientation: landscape) and (max-width: 736px) {
   .title {
      font-size:24px;
   }

   .icon {
    width:20px;
   }

   .header-row {
    padding:0 10px;
   }
}

#city-selector {
  width:204px;
  height:35px;
  background-color:#292929;
  font-family:'Space Mono', monospace;
  color:rgba(245,245,245,0.9);
  letter-spacing:0.25em;
  text-transform:uppercase;
  font-size:1em;
  font-weight:400;
  padding:5px 10px;
}

@media (orientation: landscape) and (max-width: 736px) {
   #city-selector {
    height:20px;
    font-size:0.8em;
   }
}

#about {
  height:100%;
  width:100vw;
  background-color:rgba(0,0,0,0.67);
  position:fixed;
  top:0;
  z-index:999;
  display:flex;
  align-items:center;
  justify-content:center;
  overflow:scroll;
}

#about-focus {
  width:90vw;
  margin:0 auto;
  background-color:#2A2A2A;
}

.x-icon {
  color:white;
}

.about-header {
  padding:15px;
}

.about-content {
 text-align:center;
 padding:10px 0 30px 0;
}

.about-content p {
  text-align:left;
  font-family:'Open Sans', sans-serif;
  font-size:1em;
  line-height:1.5em;
  padding:0 35px;
}

.about-content a {
  color:white;
  font-weight:bold;
}

#about-footer {
  font-size:13px;
}


@media (min-width: 768px) {

  #about {
    width:100vw;
  }

  #about-focus {
    width:60vw;
    margin:0 auto;
  }

}

@media (max-width: 320px) {
  #about-focus {
    overflow:scroll;
  }
}

@media (max-width:900px) {
   #about-focus {
    width:90vw;
    height:70vh;
    font-size:1em;
    overflow:scroll;
  }

  .about-content img {
    margin-top:-20px;
    width:70px;
  }

}

#language-selector {
  z-index:998;
  width:100%;
  position:fixed;
  top:0;
  bottom:0;

}

#language-selector ul {
  position:fixed;
  right:5px;
  top:5px;
  list-style:none;
  padding:5px 0;
  background-color:#292929;
  box-shadow:1px 1px 17px rgba(0,0,0,0.1);
  z-index:999;
}

.translator-option {
  padding:0 30px;
  font-family:'Space Mono', sans-serif;
  font-weight:700;
  size:15px;
  line-height:39px;
  width:100%;
}

.translator-option:hover {
  text-decoration:underline;
  cursor:pointer;
}

footer {
  background-color:#1C1C1C;
  height:20%;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  margin-top:10px;
}

.footer-small {
  color:white;
  font-size:7px;
  width:90%;
  text-align:center;
  margin:0 auto;
  padding-bottom:5px;
}


.logo {
  width:189px;
  padding:10px;
}

.logo-footer {
  margin-top:10px;
  width:100%;
  text-align:center;
}

@media (min-width: 1400px) {
   .logo-footer {
    position:fixed;bottom:0;
   }
}

@media (orientation: landscape) and (max-width: 736px) {
   .logo {
    width:100px;
   }
}

@media (orientation: portrait) and (min-height: 750px) {
   .logo-footer {
    position:fixed;
    bottom:0;
   }
}

#switcher {
  width:266px;
  height:50px;
  border:1px solid #BBBBBB;
  display:flex;
  justify-content:center;
  cursor:pointer;
}

.switch-view {
  width:133px;
  display:flex;
  justify-content:center;
  border:1px solid #BBBBBB;
}

.switch-view.selected {
  background-color:white;
}

@media (orientation: landscape) and (max-width: 736px) {
   #switcher {
    width:240px;
    height:20px;
    margin-top:5px;
   }
}

.mapboxgl-popup-content{
  background-color: rgba(41, 41, 41, .9) !important;
  border: 1px solid white;
  border-radius: 0 !important;
  color: white;
  width: 339px;
  padding: 0 !important;
}

.mapboxgl-popup-content button{
  background-image: url(/static/media/x.a52ceee6.svg);
  left: 16px !important;
  top: 16px !important;
  right: initial !important;
  color: transparent;
  box-sizing: border-box;
  padding: 0 !important;
  width: 19px;
  height: 19px;
  background-position: center;
}

.mapboxgl-popup-tip{
  border-top-color: white !important;
}

.ride-info{
  max-width: 271px;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 30px;
}


.ride-info h3{

font-family: 'Space Mono';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
letter-spacing: 0.25em;
text-transform: uppercase;
margin: 0;

/* White */

color: #FFFFFF;
}

.ride-info ul{
  width: 95%;
  padding-inline-start: 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.ride-info li{
  margin-bottom: 15px;
}

.ride-info li b{
  font-weight: 700;
}

.map-container, #map {
height: 60vh;
}

@media only screen and (min-width: 992px) {
  #map, .map-container {
    height:65vh;
  }
}

#viz {
  height:60vh;
  overflow:hidden;
}



@media only screen and (min-width: 992px) {
  #viz {
    height:65vh;
  }
}

.VizGallery{
  height: 60vh;
  overflow:scroll;
	color: white;
	text-align: center;
  background-image: linear-gradient(#0e0e0e, #4e4e4e);
}

#vizgallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
  justify-items: center;
  grid-gap: 5px;
}

.gallery-item {
  overflow:hidden;
  width: 100%;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.017);
  transition: transform 0.5s, opacity 0.5s;
}

.gallery-item:hover img {
  opacity: 0.8;
  cursor:pointer;
  transform: scale(1.00);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.VizGallery::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.VizGallery {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 1500px) {
  #vizgallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1200px) {
  #vizgallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1050px) {
  #vizgallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px) {
  #vizgallery {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  #vizgallery {
    grid-template-columns: 1fr;
  
  }
}

@media only screen and (min-width: 992px) {
  .VizGallery {
    height:65vh;
  }
}



.Gallery{
  height: 60vh;
  overflow:scroll;
	color: white;
	text-align: center;
  background-color:#0e0e0e;
}

#gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  justify-items: center;
  grid-gap: 0;
}

.gallery-item {
  overflow:hidden;
  width: 100%;
}

.gallery-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transform: scale(1.17);
  transition: transform 0.5s, opacity 0.5s;
}

.gallery-item:hover img {
  opacity: 0.8;
  cursor:pointer;
  transform: scale(1.15);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Gallery::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Gallery {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 1500px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 1200px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 1050px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 850px) {
  .gallery-item img {
    height:300px;
  }

}

@media (max-width: 750px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .gallery-item img {
    height:250px;
  }
}

@media (max-width: 450px) {
  #gallery {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .gallery-item img {
    height:200px;
  }
}

@media only screen and (min-width: 992px) {
  .Gallery {
    height:65vh;
  }
}



