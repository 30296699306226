#city-selector {
  width:204px;
  height:35px;
  background-color:#292929;
  font-family:'Space Mono', monospace;
  color:rgba(245,245,245,0.9);
  letter-spacing:0.25em;
  text-transform:uppercase;
  font-size:1em;
  font-weight:400;
  padding:5px 10px;
}

@media (orientation: landscape) and (max-width: 736px) {
   #city-selector {
    height:20px;
    font-size:0.8em;
   }
}
